import React, { useState } from "react";
import { Layout, Form, Input, Select, Button, Row, Col, Image, List, Typography, message } from 'antd';
import './landingPage.scss'
import Icon, { CheckCircleFilled, ClockCircleFilled, AlipayCircleOutlined, UsergroupAddOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import { useHistory } from "react-router-dom";
const EuropeTourPackage = () => {

    const [form] = Form.useForm(); // Ant Design form instance
    const [responseMessage, setResponseMessage] = useState(""); // To display API response
    const [errorMessage, setErrorMessage] = useState(""); // To handle errors
    const history = useHistory();
    const handleSubmit = async (values) => {
        const { Name, LastName, Phone, Email } = values;
        if (!Name || !Phone || !Email) {
            message.error('Required fields are missing');
            return;
        }
        ApiClient.post('StoreVisitors/visitorDetails', {
            Name,
            LastName,
            Phone,
            Email,
        }).then((res) => {
            if (res.status === 201) {
                message.success('User details saved successfully');
                history.push("/Thankyou");
            } else {
                message.info('Something Went Wrong');
            }
        }).catch((error) => {
            console.log(error.message)
        })
    };

    return (
        <div className="landing-page">
            <section className="hero-section"
                style={{
                    background: "url('https://i.postimg.cc/dtVV1qvS/Eiffel-Tower.jpg') no-repeat center center",
                    backgroundSize: "cover",
                    color: "#fff",
                    padding: "50px 20px", 
                    textAlign: "center",
                }}>
                <div className="container">
                    <div className="blog-wrapper"
                        style={{
                            padding: "10px",
                            textAlign: "start", 
                            margin: "auto", 
                            backgroundColor: " rgba(0, 0, 0, 0.58)",
                            borderRadius: "8px",
                            width: "90%", 
                            maxWidth: "460px", 
                        }}
                    >
                        <Typography.Title
                            level={1}
                            style={{ fontWeight: '900', color: 'white', textAlign:'center' }}
                        >
                            Explore Best of Europe
                        </Typography.Title>
                        <Typography.Paragraph style={{ color: 'white', textAlign:"center", fontSize:"large" }}>
                            <b>Get Best Flights, Tour Itinerary and Customized Packages for Europe</b>
                        </Typography.Paragraph>
                    </div>

                    <div className="booking-form"
                        style={{
                            backgroundColor: "#0b3448",
                            padding: "30px",
                            borderRadius: "10px",
                            marginTop: "20px",
                            height: "auto", 
                            width: "90%", 
                            maxWidth: "500px", 
                            marginLeft: "auto",
                            marginRight: "auto" 
                        }}>
                        <span style={{ fontSize: 'x-large', fontWeight: 'bolder' }}>Book Your Tour</span>
                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Full Name"
                                        name="Name"
                                        rules={[{ required: true, message: 'Please enter your full name!' }]}
                                    >
                                        <Input placeholder="Enter Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Last Name"
                                        name="LastName"
                                        rules={[{ required: true, message: 'Please enter your last name!' }]}
                                    >
                                        <Input placeholder="Enter Last Name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Email Address"
                                        name="Email"
                                        rules={[
                                            { required: true, message: 'Please enter your email address!' },
                                            { type: 'email', message: 'Please enter a valid email address!' },
                                        ]}
                                    >
                                        <Input type="email" placeholder="Enter Email Address" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Phone Number"
                                        name="Phone"
                                        rules={[{ required: true, message: 'Please enter your phone number!' }]}
                                    >
                                        <Input placeholder="Enter Phone Number" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block>
                                            Submit Enquiry
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        {responseMessage && <div style={{ color: 'green' }}>{responseMessage}</div>}
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    </div>
                </div>
            </section >

            <section className="container my-5">
                <div className="row">
                    {/* Left Column: Image */}
                    <div className="col-md-6">
                        <img
                            alt=" Zurich-Revolving Cable Car"
                            className="img-fluid rounded"
                            src="https://i.postimg.cc/qqgqWQZG/pexels-chris-132379-405169.png"
                            style={{height:"460px"}}
                        />
                    </div>

                    {/* Right Column: Content */}
                    <div className="col-md-6">
                        <h2>
                            Shop Your Trip can satisfy all your travel needs.
                        </h2>
                        <p style={{lineHeight:"1.5"}}>
                            We can book flights, hotels, tour activities, and tailor-made packages at affordable prices. So, say goodbye to hassle and hello to memorable travel experiences in your budget.
                        </p>

                        {/* List Section */}
                        <ul className="list-unstyled"
                            style={{
                                padding: "0",
                                listStyle: "none",
                                lineHeight: "2.2rem"
                            }}>
                            <li
                             style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Provided Payment Security
                            </li>
                            <li
                            style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Safe Journey
                            </li>
                            <li
                            style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Experienced Guide
                            </li>
                            <li
                            style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Best Sightseeing
                            </li>
                        </ul>

                        {/* Enquiry Button */}
                        <div>
                            <a className="btn btn-danger" href="#">
                                Enquiry Now
                            </a>
                        </div>

                        {/* Stats Section */}
                        <div className="d-flex justify-content-between">
                            <div>
                                <h3>75K+</h3>
                                <p>Happy Clients</p>
                            </div>
                            <div>
                                <h3>5000+</h3>
                                <p>Memorable Tours</p>
                            </div>
                            <div>
                                <h3>95%</h3>
                                <p>Client Satisfaction</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark text-black py-5">
                <div className="container-fluid" style={{ maxWidth: '1320px' }}>
                    <div className="section-title">
                        <h2>Europe FD Tour Packages</h2>
                        <p>
                            Boat Ride, Shopping Sites, Culture And More, Checkout
                            Our Europe Tour Packages For Happiness Galore.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>
                        {/* Package 1 */}
                        <div className="col-md-5">
                            <div className="card">
                                <img
                                    alt="Night view of Europe cityscape"
                                    className="card-img-top"
                                    src="https://i.postimg.cc/c1X8TkL2/Zurich-Lucerne.png"
                                />
                                <div className="card-body">
                                    <h5>9 Nights and 10 Days</h5>
                                    <ul>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Return international flights in economic class
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Daily Breakfast at hotel
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Accommodation at 4 star hotels
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All day lunch at Indian restaurent as per itinerary
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All day dinner at Indian restaurent as per itinerary
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Mineral Water Bottels
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            1 Gala Dinner + DJ + Alcoholic & Non-Alcoholic Drinks
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Everyday Masala Tea with Snacks & Cookies
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All Tours & Transfer in luxury coach
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Paris- Entry tickets for Eiffel Tower(2nd Level),
                                            1 Hour Seine River Cruise, City Tour with local guide
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Zurich- Mt.Titlis, Revolving Cable Car,
                                            Orientation Tour of Lucerne, Rhine Falls
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Innsbruck- Orientation Tour, Swarovski Crystal Museum 
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Padova- City Tour of Venice / Padova
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Florence- Leaning Tower of Pisa, Piazzale Michelangelo
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Rome- Colosseeum, Vatican City, Trevi Fountain
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            City Tax
                                        </li>
                                    </ul>
                                    <a className="btn btn-danger" href="#">
                                        Send Enquiry
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Package 2 */}
                        <div className="col-md-5">
                            <div className="card">
                                <img
                                    alt="Night view of Europe cityscape"
                                    className="card-img-top"
                                    src="https://i.postimg.cc/VkjfZ5Fv/Rome-Colosseeum.png"
                                    style={{height:"332px"}}
                                />
                                <div className="card-body">
                                    <h5>12 Nights and 13 Days</h5>
                                    <ul>
                                    <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Return international flights in economic class
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Daily Breakfast at hotel
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Accommodation at 4 star hotels
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All day lunch at Indian restaurent as per itinerary
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All day dinner at Indian restaurent as per itinerary
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Mineral Water Bottels
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            1 Gala Dinner + DJ + Alcoholic & Non-Alcoholic Drinks
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Everyday Masala Tea with Snacks & Cookies
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All Tours & Transfer in luxury coach
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Paris- Entry tickets for Eiffel Tower(2nd Level),
                                            1 Hour Seine River Cruise, City Tour with local guide
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Zurich- Mt.Titlis, Revolving Cable Car,
                                            Orientation Tour of Lucerne, Rhine Falls, Lindt Chocolate Factory 
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Innsbruck- Swarovski World Of Crystals 
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Cologne- Cologne Cathedral 
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                           Venice- Boat Tour, Gondola Ride
                                        </li>
                                        {/* <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Florence- Leaning Tower of Pisa, City Tour of Florence
                                        </li> */}
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Brussels Orientation Tour
                                        </li>
                                        {/* <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            City Tax
                                        </li> */}
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Half Day City Tour of Amsterdam, Canal Cruise
                                        </li>
                                    </ul>
                                    <a className="btn btn-danger" href="#">
                                        Send Enquiry
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>

            <section class="container my-5">
                <div class="section-title">
                    <h2 style={{
                        fontSize: "2.5rem",
                        fontWeight: "600",
                        textAlign: "center",
                        marginBottom: "15px",
                        color: "#000"
                    }}>
                        Popular Activities Open For Visitors
                    </h2>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Europe Mall" class="card-img-top" height="300" src="https://i.postimg.cc/Xvh0VC4n/Seine-River-Cruise.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Paris-Seine River Cruise
                                </h5>
                                <p class="card-text">
                                Commentary,Enjoy Panoramic Views
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="The Europe Fountain" class="card-img-top" height="300" src="https://i.postimg.cc/FKvLT72s/Rhine-Falls.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Zurich-Rhine Falls
                                </h5>
                                <p class="card-text">
                                Boat Ride, climb a Rock
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Mall of Emirates" class="card-img-top" height="300" src="https://i.postimg.cc/jjRDzB7D/Leaning-Tower-of-Pisa.png" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Florence- Leaning Tower of Pisa
                                </h5>
                                <p class="card-text">
                                Climb the Tower, Learn its History 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="The Burj Khalifa" class="card-img-top" height="300" src="https://i.postimg.cc/qRhQTtvV/Padua-City-Tour-of-Padua.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Padua- City Tour
                                </h5>
                                <p class="card-text">
                                Observe Churches, Art, Museums
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Europe Marina" class="card-img-top" height="300" src="https://i.postimg.cc/rwk1Pn4k/Rome-Trevi-Fountain.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Rome- Trevi Fountain
                                </h5>
                                <p class="card-text">
                                Enjoy Architecture and Sculptures. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Palm Jumeirah" class="card-img-top" height="300" src="https://i.postimg.cc/LX26dPTh/Innsbruck-Sw-arovski-Crystal-Museum.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Innsbruck- Swarovski Crystal
                                </h5>
                                <p class="card-text">
                                    Destination for Art lovers, Families
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark text-white py-5">
                <div className="container">
                    <div className="section-title">
                        <h2 style={{textAlign:"center"}}>Why Shop Your Trip?</h2>
                        <p
                            style={{
                                fontSize: "1rem",
                                lineHeight: "1.6",
                                maxWidth: "72ch",
                                textAlign: "justify",
                                margin: "0 auto 30px",
                                color: "#fff",
                            }}>
                            Shop Your Trip can satisfy all your travel needs. Here, you can book flight tickets, hotels, bus tickets, activities, and holiday packages at a cost-effective price. So, why go anywhere else? Visit us for a memorable travel experience in your budget.
                        </p>
                    </div>
                    <div className="row">
                        {/* Left Image */}
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <img
                                src="https://i.postimg.cc/bJbqND1c/SYTTeam.jpg"
                                alt="Europe City"
                                className="img-fluid rounded"
                            />
                        </div>

                        {/* Right Features List */}
                        <div className="col-lg-6">
                            <ul className="list-unstyled" style={{lineHeight:"0.9"}}>
                                <li>
                                    <ClockCircleFilled style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Save Time</h5>
                                        <p>We invest in multiple sites to compare prices for you.</p>
                                    </div>
                                </li>
                                <li>
                                    <AlipayCircleOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Save Money</h5>
                                        <p>Compare multiple sites to find the best deal for you.</p>
                                    </div>
                                </li>
                                <li>
                                    <AppstoreAddOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Multiple Options</h5>
                                        <p>Multiple itineraries & personalized suggestions based on interest.</p>
                                    </div>
                                </li>
                                <li>
                                    <UsergroupAddOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Trusted Network</h5>
                                        <p>Reliable, flexible & verified travel guides & exclusive information.</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="text-center mt-4">
                                <a href="#" className="btn btn-danger">
                                    Send Enquiry
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default EuropeTourPackage