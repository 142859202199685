import React, { useState } from "react";
import { Layout, Form, Input, Select, Button, Row, Col, Image, List, Typography, message } from 'antd';
import './landingPage.scss'
import Icon, { CheckOutlined, CheckCircleFilled, ClockCircleFilled, AlipayCircleOutlined, UsergroupAddOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import { useHistory } from "react-router-dom";
const JapanLandingPage = () => {

    const [form] = Form.useForm(); // Ant Design form instance
    const [responseMessage, setResponseMessage] = useState(""); // To display API response
    const [errorMessage, setErrorMessage] = useState(""); // To handle errors
    const history = useHistory();
    const handleSubmit = async (values) => {
        const { Name, LastName, Phone, Email } = values;
        if (!Name || !Phone || !Email) {
            message.error('Required fields are missing');
            return;
        }
        ApiClient.post('StoreVisitors/visitorDetails', {
            Name,
            LastName,
            Phone,
            Email,
        }).then((res) => {
            if (res.status === 201) {
                message.success('User details saved successfully');
                history.push("/Thankyou");
            } else {
                message.info('Something Went Wrong');
            }
        }).catch((error) => {
            console.log(error.message)
        })
    };

    return (
        <div className="landing-page">
            <section className="hero-section"
                style={{
                    background: "url('https://i.postimg.cc/c4LWj4Xm/SkyTree.jpg') no-repeat center center",
                    backgroundSize: "cover",
                    color: "#fff",
                    padding: "50px 20px",
                    textAlign: "center"
                }}>
                <div className="container">
                    <div className="blog-wrapper"
                        style={{
                            padding: "10px",
                            textAlign: "start",
                            margin: "auto",
                            backgroundColor: " rgba(0, 0, 0, 0.58)",
                            borderRadius: "8px",
                            width: "90%",
                            maxWidth: "460px",
                        }}
                    >
                        <Typography.Title
                            level={1}
                            style={{ fontWeight: '900', color: 'white', textAlign: 'center' }}
                        >
                            Explore Best of Japan
                        </Typography.Title>
                        <Typography.Paragraph style={{ color: 'white', textAlign: "center", fontSize: "large" }}>
                            <b>Get Best Flights, Tour Itinerary and Customized Packages for Japan</b>
                        </Typography.Paragraph>
                    </div>

                    <div className="booking-form"
                        style={{
                            backgroundColor: "#0b3448",
                            padding: "30px",
                            borderRadius: "10px",
                            marginTop: "20px",
                            height: "auto",
                            width: "90%",
                            maxWidth: "500px",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}>
                        <span style={{ fontSize: 'x-large', fontWeight: 'bolder' }}>Book Your Tour</span>
                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Full Name"
                                        name="Name"
                                        rules={[{ required: true, message: 'Please enter your full name!' }]}
                                    >
                                        <Input placeholder="Enter Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Last Name"
                                        name="LastName"
                                        rules={[{ required: true, message: 'Please enter your last name!' }]}
                                    >
                                        <Input placeholder="Enter Last Name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Email Address"
                                        name="Email"
                                        rules={[
                                            { required: true, message: 'Please enter your email address!' },
                                            { type: 'email', message: 'Please enter a valid email address!' },
                                        ]}
                                    >
                                        <Input type="email" placeholder="Enter Email Address" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Phone Number"
                                        name="Phone"
                                        rules={[{ required: true, message: 'Please enter your phone number!' }]}
                                    >
                                        <Input placeholder="Enter Phone Number" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block>
                                            Submit Enquiry
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        {responseMessage && <div style={{ color: 'green' }}>{responseMessage}</div>}
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    </div>
                </div>
            </section >

            <section className="container my-5">
                <div className="row">
                    {/* Left Column: Image */}
                    <div className="col-md-6">
                        <img
                            alt="Osaka Castle"
                            className="img-fluid rounded"
                            src="https://i.postimg.cc/HxrZ5R2b/Osaka-Castle.jpg"
                            style={{ height: "460px" }}
                        />
                    </div>

                    {/* Right Column: Content */}
                    <div className="col-md-6">
                        <h2>
                            Shop Your Trip can satisfy all your travel needs.
                        </h2>
                        <p style={{ lineHeight: "1.5" }}>
                            We can book flights, hotels, tour activities, and tailor-made packages at affordable prices. So, say goodbye to hassle and hello to memorable travel experiences in your budget.
                        </p>

                        {/* List Section */}
                        <ul className="list-unstyled"
                            style={{
                                padding: "0",
                                listStyle: "none",
                                lineHeight: "2.2rem"
                            }}>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                                }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Provided Payment Security
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                                }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Safe Journey
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                                }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Experienced Guide
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                                }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Best Sightseeing
                            </li>
                        </ul>

                        {/* Enquiry Button */}
                        <div>
                            <a className="btn btn-danger" href="#">
                                Enquiry Now
                            </a>
                        </div>

                        {/* Stats Section */}
                        <div className="d-flex justify-content-between">
                            <div>
                                <h3>75K+</h3>
                                <p>Happy Clients</p>
                            </div>
                            <div>
                                <h3>5000+</h3>
                                <p>Memorable Tours</p>
                            </div>
                            <div>
                                <h3>95%</h3>
                                <p>Client Satisfaction</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark text-black py-5">
                <div className="container-fluid" style={{ maxWidth: '1320px' }}>
                    <div className="section-title">
                        <h2>Japan FD Tour Packages</h2>
                        <p>
                            Ropeway, Shopping Sites, Culture And More, Checkout
                            Our Japan Tour Packages For Happiness Galore.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                        </div>
                        {/* Package 1 */}
                        <div className="col-md-8">
                            <div className="card">
                                <img
                                    alt="Lake Ashi Cruise"
                                    className="card-img-top"
                                    src="https://i.postimg.cc/MTzZp0mk/Lake-Ashi-Cruise.jpg"
                                />
                                <div className="card-body">
                                    <h5>7 Nights and 8 Days (INCLUSIONS)</h5>
                                    <ul>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Return Airfare in economy class
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Accommodation at 4 star hotels on twin / double sharing
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All day Breakfast
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All day lunch and dinner as per itinerary
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            English Speaking Guide as per itinerary
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Entrance Ticket of Hakone Ropeway(01Way), Lake Ashi Cruise,
                                            Kinkakuji Temple, Todaiji Temple Grand(Budha Hall),
                                            Miyajima Ferry, Itsukushima Shrine, Hiroshima Peace Park
                                            & Museum
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Bullet train reserved Economy class ticket from Tokyo to Hiroshima
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Baggage transfer from Tokyo to Osaka(01 Pc. Per Person)
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Daily water bottled in car (500 ML * 2 Bottle per Person)
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All Tour and Transfers in luxury coach on pvt basis(Max 10 Hours Per Day)
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Currently applicable Japan Tax
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            03 Nights Stay In Tokyo & Osaka and 1 Night In Hiroshima
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Hotels (Mystays Premier, The Royal Park, Mitsui Garden)
                                            {/* <ul><br></br>
                                                <li>
                                                    <CheckOutlined style={{ color: 'green' }} />
                                                    Tokyo(3 Nights)- Hotel Mystays Premier Akasaka / Similar
                                                </li>
                                                <li >
                                                    <CheckOutlined style={{ color: 'green' }} />
                                                    Hiroshima(1 Night)- The Royal Park Hotel Hiroshima River Side / Similar
                                                </li>
                                                <li >
                                                    <CheckOutlined style={{ color: 'green' }} />
                                                    Osaka(3 Nights)- Mitsui Garden Osaka Premier / Similar
                                                </li>
                                            </ul> */}
                                        </li>
                                    </ul>
                                    <a className="btn btn-danger" href="#">
                                        Send Enquiry
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>
                </div>
            </section>

            <section class="container my-5">
                <div class="section-title">
                    <h2 style={{
                        fontSize: "2.5rem",
                        fontWeight: "600",
                        textAlign: "center",
                        marginBottom: "15px",
                        color: "#000"
                    }}>
                        Popular Activities Open For Visitors
                    </h2>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Japan Mall" class="card-img-top" height="300" src="https://i.postimg.cc/9XpRZ0Dt/Hakone-Ropeway.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Hakone Ropeway
                                </h5>
                                <p class="card-text">
                                    Enjoy views of Mt. Fuji and Lake Ashi.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="The Japan Fountain" class="card-img-top" height="300" src="https://i.postimg.cc/h45nkR6R/Kinkakuji-Temple.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Kinkakuji Temple
                                </h5>
                                <p class="card-text">
                                    Magnificent Golden Pavilion
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Itsukushima Shrine" class="card-img-top" height="300" src="https://i.postimg.cc/VsjTRQwF/Itsukushima-Shrine.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Itsukushima Shrine
                                </h5>
                                <p class="card-text">
                                    Pray for safety of the Seto Inland Sea
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="The Burj Khalifa" class="card-img-top" height="300" src="https://i.postimg.cc/MZbPB70P/Japan-s-bullet-train.webp" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Bullet Train
                                </h5>
                                <p class="card-text">
                                    Observation Speed, Food, Nature
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Japan Marina" class="card-img-top" height="300" src="https://i.postimg.cc/WzChzF4M/Hiroshima-Peace-Park-Museum.webp" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Peace Park & Museum
                                </h5>
                                <p class="card-text">
                                Prayer for the Atomic bomb Victims
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Palm Jumeirah" class="card-img-top" height="300" src="https://i.postimg.cc/qB3tDwDw/Todaiji-Temple.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Todaiji Temple
                                </h5>
                                <p class="card-text">
                                Center of National Ritual
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark text-white py-5">
                <div className="container">
                    <div className="section-title">
                        <h2 style={{ textAlign: "center" }}>Why Shop Your Trip?</h2>
                        <p
                            style={{
                                fontSize: "1rem",
                                lineHeight: "1.6",
                                maxWidth: "72ch",
                                textAlign: "justify",
                                margin: "0 auto 30px",
                                color: "#fff",
                            }}>
                            Shop Your Trip can satisfy all your travel needs. Here, you can book flight tickets, hotels, bus tickets, activities, and holiday packages at a cost-effective price. So, why go anywhere else? Visit us for a memorable travel experience in your budget.
                        </p>
                    </div>
                    <div className="row">
                        {/* Left Image */}
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <img
                                src="https://i.postimg.cc/DzGDMmH8/SYTTeams.jpg"
                                alt="Japan City"
                                className="img-fluid rounded"
                            />
                        </div>

                        {/* Right Features List */}
                        <div className="col-lg-6">
                            <ul className="list-unstyled" style={{ lineHeight: "0.9" }}>
                                <li>
                                    <ClockCircleFilled style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Save Time</h5>
                                        <p>We invest in multiple sites to compare prices for you.</p>
                                    </div>
                                </li>
                                <li>
                                    <AlipayCircleOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Save Money</h5>
                                        <p>Compare multiple sites to find the best deal for you.</p>
                                    </div>
                                </li>
                                <li>
                                    <AppstoreAddOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Multiple Options</h5>
                                        <p>Multiple itineraries & personalized suggestions based on interest.</p>
                                    </div>
                                </li>
                                <li>
                                    <UsergroupAddOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Trusted Network</h5>
                                        <p>Reliable, flexible & verified travel guides & exclusive information.</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="text-center mt-4">
                                <a href="#" className="btn btn-danger">
                                    Send Enquiry
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default JapanLandingPage