import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Layout,
  Radio,
  Switch,
  Spin,
} from "antd";

import React, { useEffect, useState } from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import APIClient from "../../helpers/ApiClient";
import search from "../assets/vector-icons/search.png";

const ActivitiesCities = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const [reportModal, setReportModalView] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const { Option } = Select;
  const [AgentField, setAgentField] = useState(false);
  const [Agentlist, setAgentlist] = useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [Visible, setVisible] = React.useState(false);
  const { TextArea } = Input;
  const [CityList, setCityList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [loding, setLoding] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(-1);
  const [status, setStatus] = useState(1);
  const [CityId, setCityId] = useState(null);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  const categoryColumn = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "City Name",
      dataIndex: "cityName",
      // sorter: (a, b) => a.cityName.localeCompare(b.cityName),
    },
    {
      title: "Country Name",
      dataIndex: "countryName",
      // sorter: (a, b) => a.countryName.localeCompare(b.countryName),
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const updateCityForm = (currentFormData) => {
    setId(currentFormData.id);
    setCityId(currentFormData.cityId)

    form.setFieldsValue({
      ...currentFormData,
    });
    setIsEditMode(true);
  };

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}

          // onClick={() => updateFlightComm(currentFlightComm)}
        >
          <EditOutlined
            onClick={() => updateCityForm(CurrentItem)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteActivitiesCity(CurrentItem.id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const changeStatus = (info, status) => {
    const id = info.categoryId;
    delete info.SNo;
    delete info.categoryId;
    let obj = {
      ...info,
      status: status,
    };
    // UpdateActivitiesCities(obj, id);
  };

  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;
  const searchView = () => {
    setShowTable(true);
  };

  const reset = () => {
    form.resetFields();
  };

  // search ***********************************

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = CityList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCityList(searchResults);
    } else {
      getActivitiesCities();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const onFinish = (val) => {
    debugger
    let obj = {
      cityName: val.cityName,
      countryId: val.countryId,
      cityId: isEditMode ? CityId : val?.cityId,
      countryName: CountryList.filter(
        (item, index) => item.countryId == val.countryId
      )[0].countryName,
      // cityId: CityList[CityList.length - 1].cityId + 1,
      isEuropeContinent : val.isEuropeContinent,
    };
    isEditMode ? UpdateActivitiesCities(obj, id) : SaveActivitiesCities(obj);
  };

  /** API CALLS */

  const getActivitiesCities = () => {
    APIClient.get(`extranet/activitiesCities`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((city, index) => {
            return {
              SNo: index + 1,
              ...city,
            };
          });
          setCityList(data);
          setLoding(false);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch City Data", 3);
          setLoding(false);
        }
      })
      .catch(() => {});
  };

  const getActivitiesCountries = () => {
    setCountryList([]);
    APIClient.get(`extranet/activitiesCountries`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((country, index) => {
            return {
              SNo: index + 1,
              ...country,
            };
          });
          setCountryList(data);
        }
      })
      .catch(() => {});
  };

  const SaveActivitiesCities = (data) => {
    APIClient.post("extranet/addActivitiesCities", data)
      .then((resp) => {
        if (resp.status == 200) {
          message.success(resp.message, 3);
          getActivitiesCities();
          form.resetFields();
        } else if (resp.status == 400) {
          message.error(resp.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateActivitiesCities = (formMapData, cityId) => {
    APIClient.put(`extranet/updateActivitiesCities/${cityId}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success(response.message, 3);
          getActivitiesCities();
        } else if (response.status == 404) {
          message.error(response.message, 3);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteActivitiesCity = (cityId) => {
    // APIClient.delete("extranet/deleteActivitiesCities/" + cityId)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       message.success(res.message, 3);
    //       getActivitiesCities();
    //     }
    //     if (res.status == 403) {
    //       message.error(res.message, 3);
    //       getActivitiesCities();
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    message.error("You Can't be Deleted");
  };

  useEffect(() => {
    getActivitiesCities();
    getActivitiesCountries();
  }, []);

  const validateMessages = {
    required: "",
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <h5>
                      Activities Cities{" "}
                      <HelpInfoHelper screenName={"/admin/activitiesCities"} />
                    </h5>
                    {/* onClick={handleShow} */}
                    <div className="add-icon d-none">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <div>
                    &nbsp;
                    <Form
                      {...formItemLayout}
                      layout={formLayout}
                      form={form}
                      onFinish={onFinish}
                      validateMessages={validateMessages}
                      initialValues={{
                        isEuropeContinent: 0,
                      }}
                    >
                      <Row>
                        <Col md={7} xs={24}>
                          <Form.Item
                            label="City Name"
                            name="cityName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter City Name",
                              },
                            ]}
                          >
                            <Input placeholder="City Name" />
                          </Form.Item>
                        </Col>
                        <Col md={7} xs={24}>
                          <Form.Item
                            label="Country"
                            name="countryId"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Country"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item, index) => (
                                <Option key={index} value={item.countryId}>
                                  {item.countryName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            label="European City"
                            name="isEuropeContinent"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="reports-btns">
                        <Col>
                          {/* <div > */}
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              {isEditMode ? "Update" : "Add"}
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button type="danger" onClick={reset}>
                              Reset
                            </Button>
                            {/* </div> */}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
              </div>
            </Col>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>View Report</h5>
                        <p>{CityList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                  <div>
                    {loding ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : CityList.length > 0 ? (
                      <Table
                        className="table-scroll-none table-data"
                        bordered
                        dataSource={CityList}
                        columns={categoryColumn}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  );
};

export default ActivitiesCities;
