import React, { useState, useEffect } from "react";
import { Button, Col, Table, Row, Skeleton, message, Tabs } from "antd";

import ApiClient from "../../helpers/ApiClient";

import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import "./CouponWallet.scss";

const OfferSkeleton = () => {
  return (
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="coupon-card">
        <div className="coupon-content">
          <Skeleton active />
        </div>
      </div>
    </Col>
  );
};

const OffersBox = ({ coupon, getCouponWalletByUserId, userID }) => {
  // const currentDate = moment().startOf("day");
  const currentDate = moment();
  const travel = moment(coupon.TravelDate, "DD-MM-YYYY");
  let isdateValid = moment(currentDate).isAfter(travel);
  // const redeemDate = moment(coupon.RedeemDate, "DD-MM-YYYY");
  // let isRedeemDateValid = moment(redeemDate).isSame(currentDate);

  if (isdateValid && coupon.RedeemStatus == "YES") {
    isdateValid = false;
  }
  const updateRedeem = (value) => {
    delete value.SNo;
    delete value.ServiceName;
    delete value.RedeemDate;
    let id = value.ID;
    let obj = {
      ...value,
      RedeemStatus: 1,
      CouponAmount: parseFloat(value.CouponAmount),
      ModifiedDate: new Date(),
    };

    RedeemCoupon(id, obj);
  };

  const RedeemCoupon = (id, obj) => {
    ApiClient.put("admin/updateCouponsWallet/" + id, {}, obj).then((res) => {
      if (res.status == 200) {
        message.success("Coupan Amount Redeem  successfully", 3);

        if (userID) {
          getCouponWalletByUserId(userID);
        }
      } else if (res.status == 400) {
        message.error(res.message, 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  return (
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="coupon-card ">
        <div className="coupon-content">
          <div className="coupon-code">
            <div className="codes">
              <h2>{coupon.ServiceName}</h2>
              {/* <h6>{coupon.ReferenceNumber}</h6> */}
            </div>
            <p className="amt_text">
              Get <span>INR {coupon.CouponAmount}</span> in your wallet.
            </p>
            <p className="red_text mb-0">Redeem on : {coupon.RedeemDate}</p>
          </div>
        </div>

        {isdateValid
        //  && isRedeemDateValid 
         ? (
          <div className="redeem-btn">
            <Button type="text" onClick={() => updateRedeem(coupon)}>
              Redeem Now
            </Button>
          </div>
        ) : (
          // <div className="valid-from">Valid From: {coupon.RedeemDate}</div>
          <div className="redeem-btn">
            <Button type="text" disabled={true}>
              Redeem Now
            </Button>
          </div>
        )}
      </div>
    </Col>
  );
};

/** Sperate Logic For Each Coupon By Karthik 16/Oct/2024 */
const RedeemCoupon = ({coupon}) => {
  return(
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="card border border-dark text-center">
          <div className="code">
            <h4>{coupon.ServiceName}</h4>
            <p className="amt_text">
              Get <span>INR {coupon.CouponAmount}</span> in your wallet.
            </p>
            <p className="red_text mb-0">Redeem on : {coupon.RedeemDate}</p>
          </div>
          <div className="redeem-btn">
            <Button type="button" disabled={true}>
              Claimed Coupon
            </Button>
          </div>
      </div>
    </Col>
  )
}

const NonRedeemCoupon = ({ coupon, getCouponWalletByUserId, userID }) => {
  const currentDate = moment().startOf('day');
  const travel = moment(coupon.RedeemDate, "DD-MM-YYYY");
  let isDateValid = travel.isAfter(currentDate);

  const updateRedeem = (value) => {
    delete value.SNo;
    delete value.ServiceName;
    delete value.RedeemDate;
    let id = value.ID;
    let obj = {
      ...value,
      RedeemStatus: 1,
      CouponAmount: parseFloat(value.CouponAmount),
      ModifiedDate: new Date(),
    };

    RedeemCoupon(id, obj);
  };

  const RedeemCoupon = (id, obj) => {
    ApiClient.put("admin/updateCouponsWallet/" + id, {}, obj).then((res) => {
      if (res.status == 200) {
        message.success("Coupan Amount Redeem  successfully", 3);

        if (userID) {
          getCouponWalletByUserId(userID);
        }
      } else if (res.status == 400) {
        message.error(res.message, 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  return(
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="card border border-dark text-center">
          <div className="code">
            <h4>{coupon.ServiceName}</h4>
            <p className="amt_text">
              Get <span>INR {coupon.CouponAmount}</span> in your wallet.
            </p>
            <p className="red_text mb-0">Redeem on : {coupon.RedeemDate}</p>
          </div>
          {isDateValid ? (
          <div className="redeem-btn">
            <Button style={{backgroundColor: "rgba(0,0,0,.2)"}} className="bg-primary" type="text" onClick={() => updateRedeem(coupon)}>
              Redeem Now
            </Button>
          </div>
          ) : (
            <div className="redeem-btn">
              <Button style={{backgroundColor: "#1e4167"}} className="border border-dark text-light" type="text" disabled={true}>
                Expire on : {moment(coupon.RedeemDate, "DD-MM-YYYY").add(1, 'days').format("DD-MM-YYYY")}
              </Button>
            </div>
          )}
      </div>
    </Col>
  )
}
/** Code End Here */

const Offers = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [couponList, setCouponList] = useState([]);
  const [couponList, setCouponList] = useState({
    RedeemedCoupon: [],
    NonRedeemCoupon: []
  });
  const [tableData, setTableData] = useState([]);
  const [totalRedeemedAmount, setTotalRedeemedAmount] = useState(0);

  const { user } = useAuthContext();

  let userId = 1;
  if (user) {
    if (user.UserID) {
      userId = user.UserID;
    }
  }

  useEffect(() => {
    if (user && user?.UserID) {
      getCouponWalletByUserId(user?.UserID);
    }
  }, [user]);

  const getCouponWalletByUserId = (id) => {
    ApiClient.get(`admin/getCouponsWalletByUserId/${id}`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          let filteredList = resp.data.filter(
            (item) => item.RedeemStatus == "YES"
          );
          setTableData(filteredList);
          // let redeemedAmount = filteredList?.reduce(
          //   (a, b) => a + Number(b.CouponAmount),
          //   0
          // );
          let redeemedAmount = resp.data[0].TotalCouponAmount;
          setTotalRedeemedAmount(redeemedAmount);

          let redeemedCoupon = data.filter(x => x.RedeemStatus.toUpperCase() === "YES");
          let NonRedeemedCoupon = data.filter(x => x.RedeemStatus.toUpperCase() !== "YES");
          // setCouponList(data);
          setCouponList((prev) => ({
            ...prev,
            RedeemedCoupon: redeemedCoupon,
            NonRedeemCoupon: NonRedeemedCoupon
          }))
          setIsLoading(false);
        } else {
          // setCouponList([]);
          setCouponList({
            RedeemedCoupon: [],
            NonRedeemCoupon: [],
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "SNo",
    //   key: "SNo",
    // },
    {
      title: "Service Name",
      dataIndex: "ServiceName",
      key: "ServiceName",
    },
    {
      title: "Coupon Amount",
      dataIndex: "CouponAmount",
      key: "CouponAmount",
    },
    {
      title: "Redeem Date",
      dataIndex: "RedeemDate",
      key: "RedeemDate",
    },
    {
      title: "Reference Number",
      dataIndex: "ReferenceNumber",
      key: "ReferenceNumber",
    },
    {
      title: "Redeem Status",
      dataIndex: "RedeemStatus",
      key: "RedeemStatus",
    },
  ];

  return (
    <div className="coupon-wallet">
      <div className="coupon-wallet-wrapper">
        <div className="container mt-3">
          <div className="tab1-content">
            {/* <Row gutter={[32, 32]}>
              {isLoading ? (
                <OfferSkeleton />
              ) : couponList.length > 0 ? (
                couponList.map((coupon, i) => (
                  <OffersBox
                    coupon={coupon}
                    getCouponWalletByUserId={getCouponWalletByUserId}
                    userID={user?.UserID}
                    key={i}
                  />
                ))
              ) : (
                <p>No Coupons Available</p>
              )}
            </Row> */}
            {/* Sperate Redeem & Non-Redeem Coupon By Karthik 16-Oct-2024 */}
            <Row gutter={[32, 32]}>
              {isLoading ? (
                <OfferSkeleton />
              ) : (couponList.RedeemedCoupon.length > 0 || couponList.NonRedeemCoupon.length > 0) ? (
                <Tabs defaultActiveKey="1" style={{width: "100%"}}>
                  <Tabs.TabPane tab="Redeem Coupon" key="1">
                    {couponList.RedeemedCoupon.map((coupon, i) => (
                      <RedeemCoupon
                        coupon={coupon}
                      />
                    ))}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Non-Redeem Coupon" key="2">
                    {couponList.NonRedeemCoupon.map((coupon, i) => (
                      <NonRedeemCoupon
                        coupon={coupon}
                        getCouponWalletByUserId={getCouponWalletByUserId}
                        userID={user?.UserID}
                        key={i}
                      />
                    ))}
                  </Tabs.TabPane>
                </Tabs>
              ) : (
                <p>No Coupons Available</p>
              )}
              {/* End Code Here */}
            </Row>
          </div>
        </div>
      </div>
      <div className="container my-3 wallect-ac-21">
        <div className="row py-2">
          <div className="col-6">Coupons Redeemed</div>
          <div className="col-6 text-right">
            Total Amount Redeemed :INR {Number(totalRedeemedAmount).toFixed(2)}
          </div>
        </div>
        <Table
          className="table-scroll-none"
          bordered
          dataSource={tableData}
          columns={columns}
          pagination={{
            defaultPageSize: 25,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100", "125"],
          }}
        />
      </div>
    </div>
  );
};
export default Offers;
