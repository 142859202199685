import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import InvoiceBankLogo from "../../../assets/images/invoicebanklogo.jpeg";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    padding: "0 8px",
    color: "#555",
    background: "#FFF",
  },
  header: {
    padding: "7px 0",
    marginBottom: "10px",
  },
  company: { width: "100%", marginBottom: "20px" },
  company_h: {
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  company_p: {
    textAlign: "right",
    fontSize: "8px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  invoice_h: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    margin: 0,
  },
  invoice: {
    width: "100%",
  },
  invoice_origin_h: {
    fontSize: "9px",
    fontWeight: "normal",
    width: "100%",
    marginBottom: 10,
    color: "#E32025",
  },
  origin: {
    width: "40%",
  },
  origin1: {
    width: "60%",
  },
  origin_title: { width: "30%", padding: "3px 6px" },
  origin_h: {
    fontSize: "8px",
    fontWeight: "bold",
  },
  origin_p: {
    fontSize: "8px",
    fontWeight: "normal",
  },
  origin_title1: { width: "40%", padding: "3px 6px" },
  origin_deatil1: { width: "50%", padding: "3px 6px" },
  table_p: {
    fontSize: "8px",
    fontWeight: "normal",
    marginBottom: 0,
  },
  origin_deatil: { width: "50%", padding: "3px 6px" },
  origin_deatil_right: { width: "50%" },
  origin_title_right: { width: "50%" },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 6,
    flexWrap: "wrap",
  },

  table_head: {
    width: "100%",
    color: "#000",
    border: "1px solid #000",
  },
  table_des: {
    width: "30%",
    //  borderRight: "1px solid black",
    padding: "0 3px",
  },
  table_pnr: {
    width: "10%",
    borderRight: "1px solid black",

    // padding: "0 3px",
  },
  table_pnr1: {
    width: "20%",
    borderRight: "1px solid black",

    // padding: "0 3px",
  },

  borderBottom: { borderBottom: "1px solid black" },
  table_p: {
    padding: "2px",
    fontSize: "7px",
    fontWeight: "bolder",
    color: "#000",
  },
  table_p1: {
    padding: "2px",
    fontSize: "7px",
    color: "#525151",
  },
  table_sub_index: {
    width: "10%",

    padding: "0 3px",
  },

  text_right: {
    textAlign: "right",
  },
  table_sub_wrapper: {},

  sub_table1: { marginTop: 10 },
  sub_table: {
    border: "1px solid black",
    width: "60%",
    marginLeft: "auto",
  },

  m_0: {
    margin: 0,
  },
  p_0: {
    padding: 0,
  },
  sub_table_ph: {
    width: "60%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 6px",
    borderRight: "1px solid black",
  },
  sub_table_pt: {
    width: "40%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 10px",
  },
  imgWrapper: {
    width: "100%",
    // justifyContent: "center",
    alignItems: "center",
  },

  // shkhr css
  invoicecont: {
    padding: "12px",
    border: "1px solid rgb(240,236,236)",
    borderRadius: "3px",
  },
  taxinvoicehead: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    fontWeight: "700",
    fontSize: "14px",
    marginBottom: "15px",
  },
  companyandagentaddlogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "8px",
  },
  companyadddet: {
    padding: "15px",
    border: "1px solid rgb(236,233,233)",
    borderRadius: "5px",
    width: "49%",
  },
  agentadddet: {
    padding: "15px",
    border: "1px solid rgb(239, 174, 141)",
    backgroundColor: "rgb(239, 234, 231)",
    borderRadius: "5px",
    width: "49%",
  },
  imgWrapper2: {
    margin: "10px 0px",
  },
  companynametext: {
    fontSize: "8px",
    marginBottom: "8px",
    fontWeight: "500",
    letterSpacing: 1.2,
  },
  companyauthortext: {
    fontSize: "8px",
    marginBottom: "4px",
  },
  companyaddresstext: {
    fontSize: "8px",
    marginBottom: "4px",
  },
  companytrnnotext: {
    fontSize: "8px",
    marginBottom: "2px",
  },

  agentlogoscont: {
    display: "flex",
    flexDirection: "row",
    //  marginBottom:"12px"
  },
  agentlogo1imgWrapper: {
    margin: "10px 10px 10px 0px",
  },
  agentlogo2imgWrapper: {
    margin: "10px 10px 10px 0px",
  },
  agentnametext: {
    marginBottom: "8px",
    fontWeight: "700",
    fontSize: "10px",
  },
  agentaddresstext: {
    fontSize: "8px",
    marginBottom: "6px",
  },
  agenttrnnotext: {
    fontSize: "8px",
    marginBottom: "2px",
  },

  // bookingdetailstable
  bookingdettable: {
    marginBottom: "8px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "rgb(236,233,233)",
    borderRadius: "5px",
    width: "100%",
  },
  tablerow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(236,233,233)",
  },
  cell: {
    flex: 1, // Distributes space evenly
    padding: 8,
    borderRightWidth: 1,
    borderRightColor: "rgb(236,233,233)",
    fontSize: "8px",
  },
  tableheader: {
    backgroundColor: "#f0f0f0",
    fontWeight: 900,
    fontSize: "10px",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  lastCell: {
    borderRightWidth: 0, // Remove border for the last cell
  },
  theadorange: {
    color: "rgb(225, 116, 62)",
    // fontWeight:"extrabold"
  },
  servicetypetext: {
    color: "rgb(255, 72, 0)",
    fontWeight: 900,
    padding: "10px 12px",
    width: "100%",
    backgroundColor: "rgb(237, 224, 225)",
    borderRadius: "8px",
    fontSize: "10px",
    marginBottom: "8px",
  },

  // tourtraveltable
  tourtraveltable: {
    marginBottom: "8px",
  },
  tourtableheadrow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(236,233,233)",
  },
  tourtableheader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  tourtablemrgn: {
    marginBottom: "8px",
  },

  // noteamount
  noteandamount: {
    backgroundColor: "rgb(240, 240, 240)",
    padding: "12px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "5px",
    marginBottom: "12px",
  },
  notetext1: {
    marginBottom: "8px",
  },
  notetext2: {
    color: "rgb(244, 69, 30)",
  },
  notecont: {
    fontSize: "8px",
  },
  amountdetcont: {
    // textAlign:"right",
    display: "flex",
    alignItems: "flex-end",
    backgroundColor: "#fff",
    padding: "10px 10px 10px 30px",
    fontSize: "10px",
    borderRadius: "5px",
  },
  amtamounttext: {
    marginBottom: "6px",
    textAlign: "right",
    display: "flex",
    flexDirection: "row-reverse",
  },
  amtnetamounttext: {
    textAlign: "right",
    fontWeight: "bold",
  },

  // bankdetails
  bankdetailscont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(236,233,233)",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "12px",
  },
  bankdetdata: {
    flex: 4,
  },
  bankdetimg: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginLeft:"20px"
  },
  banknametext: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "rgb(162, 108, 57)",
    marginBottom: "10px",
  },
  banknamedtltext: {
    fontSize: "9px",
    lineHeight: 1.4,
    marginBottom: "10px",
  },

  // terms and cond
  termconditioncont: {
    // break: "page",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid rgb(236,233,233)",
  },
  termcondhead: {
    color: "rgb(255,72,0)",
    fontWeight: "bold",
    fontSize: "9px",
  },
  termcondtext: {
    fontSize: "7px",
    lineHeight: 1.7,
  },
  link: {
    color: "blue",
  },
});

const getTotalPrice = (data) => {
  var Total = data.subTotal;
  if (data?.convienenceData?.amount) {
    if (data.convienenceData.type === 1) {
      Total += data.convienenceData.amount * data.passengers.length;
    } else if (data.convienenceData.type === 0) {
      Total += (Total / 100) * data.convienenceData.amount;
    }
  }
  return Total;
};
const getTax = (data) => {
  var Total = data.totalPrice;
  var subTotal = data.subTotal;
  var tax = Total - subTotal;
  return tax;
};

const FlightInvoiceDoc = ({ invoiceData, logo, type }) => {
  console.log(invoiceData, logo, type);
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.invoicecont}>
          <View style={styles.taxinvoicehead}>
            <Text style={styles.taxinvoicetext}>Tax Invoice</Text>
          </View>
          <View style={styles.companyandagentaddlogo}>
            <View style={styles.companyadddet}>
              <View style={styles.imgWrapper2}>
                {logo ? (
                  <Image
                    style={{ width: "80px", height: "40px" }}
                    src={process.env.REACT_APP_BASE_URL + logo.substring(1)}
                  />
                ) : (
                  <Image style={{ width: "80px" }} src={LogoImg} />
                )}
              </View>
              {/* <Text style={styles.companynametext}>Shop your trip</Text> */}
              {/* <Text style={styles.companyauthortext}>akash chhabra</Text> */}
              <Text style={styles.agentnametext}>
                {invoiceData?.agentDetails?.AgencyName}{" "}
              </Text>
              <Text style={styles.companyaddresstext}>
                {invoiceData?.agentDetails?.Address1}
                {","}
                {invoiceData?.agentDetails?.Address2}{" "}
              </Text>
              <Text style={styles.companyaddresstext}>
                Phone No. : {invoiceData?.agentDetails?.Phone}{" "}
              </Text>
              <Text style={styles.companytrnnotext}>GST Number :</Text>
            </View>
            <View style={styles.agentadddet}>
              <View style={styles.agentlogoscont}>
                <View style={styles.agentlogo1imgWrapper}>
                  {logo ? (
                    <Image
                      style={{ width: "70px" }}
                      //  src={process.env.REACT_APP_BASE_URL + logo.substring(1)}
                      src={LogoImg}
                    />
                  ) : (
                    <Image style={{ width: "70px" }} src={LogoImg} />
                  )}
                </View>
              </View>
              <Text style={styles.agentnametext}>Shop Your Trip</Text>
              <Text style={styles.agentaddresstext}>
                {" "}
                E-47/7, 1st Floor, Pocket-D, Okhla Phase-2, New Delhi, India,
                110020
              </Text>
              <Text style={styles.agenttrnnotext}>
                {" "}
                GST Number : 100026690600003
              </Text>
            </View>
          </View>

          {/*  booking details */}
          <View style={styles.bookingdettable}>
            <View style={styles.table}>
              {/* Header Row */}
              <View style={[styles.tablerow, styles.tableheader]}>
                <Text>Booking Details</Text>
                <Text style={styles.theadorange}>
                  {" "}
                  Booking Date :{" "}
                  {moment(invoiceData.TourDetails[0]?.bookingDate).format(
                    "DD/MM/YY"
                  )}
                </Text>
              </View>

              {/* Data Rows */}
              <View style={styles.tablerow}>
                <Text style={styles.cell}>
                  {" "}
                  Invoice No. : {invoiceData?.TourDetails[0]?.invoiceNo}
                </Text>
                <Text style={[styles.cell, styles.lastCell]}>
                  {" "}
                  Guest Name : &nbsp;&nbsp; {
                    invoiceData?.Passengers?.prefix
                  }{" "}
                  {invoiceData?.Passengers?.firstName}{" "}
                  {invoiceData?.Passengers?.lastName}
                </Text>
              </View>
              <View style={styles.tablerow}>
                <Text style={styles.cell}>
                  {" "}
                  Email Id : &nbsp;&nbsp; {invoiceData?.Passengers?.email}
                </Text>
                <Text style={[styles.cell, styles.lastCell]}>
                  {" "}
                  Mobile No. : +91{invoiceData?.Passengers?.mobile}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.servicetypetext}>
            <Text>Service Type - Tickets & Excursion</Text>
          </View>

          {/* tourtraveltable */}
          <View style={[styles.table, styles.tourtablemrgn]}>
            {/* Header Row */}
            <View style={[styles.tourtableheadrow, styles.tourtableheader]}>
              <Text style={styles.cell}>Tour Name</Text>
              <Text style={styles.cell}>Travel Date</Text>
              <Text style={styles.cell}>Time Slot</Text>
              <Text style={styles.cell}>No. of Pax</Text>
              <Text style={styles.cell}>Booking ID </Text>
              <Text style={[styles.cell, styles.lastCell]}>Total Amount</Text>
            </View>

            {/* Dynamic Data Rows */}
            {invoiceData?.TourDetails?.map((row, rowIndex) => (
              <View key={rowIndex} style={styles.tablerow}>
                <Text style={[styles.cell]}>{row?.tourName}</Text>
                <Text style={[styles.cell]}>
                  {row.journeyDate
                    ? `${moment(row.journeyDate).format("DD/MM/YY")} (${moment(
                        row.journeyDate
                      ).format("dddd")})`
                    : null}
                </Text>
                <Text
                  // key={cellIndex}
                  style={[styles.cell]}
                >
                  {row?.startTime}
                </Text>
                <Text
                  // key={cellIndex}
                  style={[styles.cell]}
                >
                  {row.pax?.adults} Adult, {row.pax?.childCount} Children,{" "}
                  {row.pax?.infantCount} Infant
                </Text>
                <Text style={[styles.cell]}>{row.referenceNumber}</Text>
                <Text style={[styles.cell, styles.lastCell]}>
                  {row.serviceTotal}
                </Text>

                {/* ))} */}
              </View>
            ))}
          </View>

          {/* noteandamount */}
          <View style={styles.noteandamount}>
            <View style={styles.notecont}>
              <Text style={styles.notetext1}>
                {" "}
                Note : All prices are inclusive of 18% GST
              </Text>
              <Text style={styles.notetext2}>
                {" "}
                "GST claim on this tax invoice can only be claimed after the
                payment of the particular invoice."
              </Text>
            </View>
            <View style={styles.amountdetcont}>
              <Text style={styles.amtamounttext}>
                Total Amount :{" "}
                {invoiceData?.TourDetails?.reduce(
                  (total, row) => total + parseFloat(row.serviceTotal || 0),
                  0
                ).toFixed(2)}
              </Text>
              <Text style={styles.amtamounttext}>
                Redeemed R Points :{" "}
                {invoiceData?.TourDetails?.reduce(
                  (total, row) => total + parseFloat(row.redeemPoints || 0),
                  0
                ).toFixed(2)}
              </Text>
              <Text style={styles.amtamounttext}>
                Coupon Discount:{" "}
                {invoiceData?.TourDetails?.reduce(
                  (total, row) => total + parseFloat(row.couponAmount || 0),
                  0
                ).toFixed(2)}
              </Text>
              {/* <Text style={styles.amtamounttext}>
                GST charges :{" "}
                {invoiceData?.TourDetails?.reduce(
                  (total, row) =>
                    total + (parseFloat(row.serviceTotal || 0) * 18) / 118,
                  0
                ).toFixed(2)}
              </Text> */}
              <Text style={styles.amtnetamounttext}>
                Final Amount : {invoiceData.totalPrice}
              </Text>
            </View>
          </View>

          {/* bankdetails */}
          {/* <View style={styles.bankdetailscont}>
           <View style={styles.bankdetdata}>
              <Text style={styles.banknametext}>{invoiceData.bankDetails[0]?.BankName}</Text>
              <Text style={styles.banknamedtltext}>
                 ACCOUNTS NAME : {invoiceData.bankDetails[0]?.BankName},
                 AC NO. :{invoiceData.bankDetails[0]?.AccountNumber},
                 IFSC NUMBER : {invoiceData?.bankDetails[0]?.IFSCNumber},
                 Account Type : {invoiceData.bankDetails[0]?.Comment}, 
                 BRANCH NAME : {invoiceData.bankDetails[0]?.BranchName},&nbsp;
                 {invoiceData.bankDetails[0]?.CountryID?.Country?
                 `COUNTRY NAME : ${invoiceData.bankDetails[0]?.CountryID?.Country}`:""} &nbsp;
                 {invoiceData.bankDetails[0]?.StateID?.State?
                 `STATE NAME : ${invoiceData.bankDetails[0]?.StateID?.State}`:""}
              </Text>
              <Text style={styles.banknametext}>{invoiceData.bankDetails[4]?.BankName}</Text>
              <Text style={styles.banknamedtltext}>
                 ACCOUNTS NAME : {invoiceData.bankDetails[4]?.BankName},
                 AC NO. :{invoiceData.bankDetails[4]?.AccountNumber},
                 IFSC NUMBER : {invoiceData?.bankDetails[4]?.IFSCNumber},
                 Account Type : {invoiceData.bankDetails[4]?.Comment}, 
                 BRANCH NAME : {invoiceData.bankDetails[4]?.BranchName},&nbsp;
                 {invoiceData.bankDetails[4]?.CountryID?.Country?
                 `COUNTRY NAME : ${invoiceData.bankDetails[4]?.CountryID?.Country}`:""} &nbsp;
                 {invoiceData.bankDetails[4]?.StateID?.State?
                 `STATE NAME : ${invoiceData.bankDetails[4]?.StateID?.State}`:""}
              </Text>
              <Text style={styles.banknametext}>{invoiceData.bankDetails[5]?.BankName}</Text>
              <Text style={styles.banknamedtltext}>
                 ACCOUNTS NAME : {invoiceData.bankDetails[5]?.BankName},
                 AC NO. :{invoiceData.bankDetails[5]?.AccountNumber},
                 IFSC NUMBER : {invoiceData?.bankDetails[5]?.IFSCNumber},
                 Account Type : {invoiceData.bankDetails[5]?.Comment}, 
                 BRANCH NAME : {invoiceData.bankDetails[5]?.BranchName},&nbsp;
                 {invoiceData.bankDetails[5]?.CountryID?.Country?
                 `COUNTRY NAME : ${invoiceData.bankDetails[5]?.CountryID?.Country}`:""} &nbsp;
                 {invoiceData.bankDetails[5]?.StateID?.State?
                 `STATE NAME : ${invoiceData.bankDetails[5]?.StateID?.State}`:""}
              </Text>
           </View>
           <View style={styles.bankdetimg}>
                   <Image 
                     style={{ width: "100px"}}
                       src={InvoiceBankLogo}
                    />
           </View>
        </View> */}
          <View style={styles.bankdetailscont}>
            <View style={styles.bankdetdata}>
              {invoiceData.bankDetails.map(
                (bank, index) =>
                  bank && (
                    <React.Fragment key={index}>
                      <Text style={styles.banknametext}>{bank?.BankName}</Text>
                      <Text style={styles.banknamedtltext}>
                        ACCOUNTS NAME : {bank?.BankName}, AC NO. :{" "}
                        {bank?.AccountNumber}, IFSC NUMBER : {bank?.IFSCNumber},
                        Account Type : {bank?.Comment}, BRANCH NAME :{" "}
                        {bank?.BranchName},&nbsp;
                        {bank?.CountryID?.Country
                          ? `COUNTRY NAME : ${bank?.CountryID?.Country}`
                          : ""}{" "}
                        &nbsp;
                        {bank?.StateID?.State
                          ? `STATE NAME : ${bank?.StateID?.State}`
                          : ""}
                      </Text>
                    </React.Fragment>
                  )
              )}
            </View>
            <View style={styles.bankdetimg}>
              <Image style={{ width: "100px" }} src={InvoiceBankLogo} />
            </View>
          </View>

          {/* terms and conditions */}
          {/* <View style={{ break: "page" }}> */}
          <View style={styles.termconditioncont}>
            <Text style={styles.termcondhead}>Terms & Conditions</Text>
            <Text style={styles.termcondtext}>
              All bookings made with Shop Your Trip are subject to our terms and
              conditions. As these conditions will be binding once the agreement
              takes effect, we strongly advise you to read and understand them
              carefully. Shop Your Trip reserves the right to modify, add, or
              remove any terms and conditions without prior notice. Such changes
              will automatically apply to you upon booking a tour or package
              with us. Failure to comply with our terms and conditions may
              result in the termination of services and the deactivation of your
              account on{" "}
              <Link src="https://shopyourtrip.com/" style={styles.link}>
                {" "}
                www.shopyourtrip.com
              </Link>
            </Text>
          </View>
          {/* </View> */}
        </View>
      </Page>
    </Document>
  );
};

export default FlightInvoiceDoc;
