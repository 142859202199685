import React from "react";
import "./FlightsCheckout.scss";
import { Card, Col, Row, Tooltip } from "antd";

import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";

import { FlightTimings } from "../SearchResults/Flight/Flight";
import { useAuthContext } from "../../common/providers/AuthProvider";
const DetailsCard = (props) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  let travelType = localStorage.getItem("airTravelType");
  let history = useHistory();

  const goTo = () => {
    history.goBack();
  };

  const FlightCard = ({ flightInfo }) => {
    let fareid = sessionStorage.getItem('FareId');
      let roundtripfareid = sessionStorage.getItem('FareId1');
      let faretype=''
      if(fareid !=undefined || roundtripfareid !=undefined)
      {
      if(flightInfo.isRoundTrip){
        faretype= flightInfo.fareFamilies.fareFamilies.filter(x=>x.fareId==roundtripfareid)[0].coupanType;
      }
      else{
        faretype= flightInfo.fareFamilies.fareFamilies.filter(x=>x.fareId==fareid)[0].coupanType;
      }
    }

    const AirportToolTip = (heading, flightDetails) => {
      return (
        <div className="tooltip-data">
          <h4>{heading}</h4>
            <div className="table rounded bg-white">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="bg-primary text-white">Airline Info</th>
                  <th className="bg-primary text-white text-center">Flight Info</th>
                  <th className="bg-primary text-white">Dept.T</th>
                  <th className="bg-primary text-white">Arr.T</th>
                </tr>
              </thead>
              <tbody>
              {flightDetails.flightSegments.map(stopDetails => (
                <tr>
                  <td className="text-center align-middle">
                    <span style={{ color: '#000000', fontWeight: 'bold' }}>{`${stopDetails.marketingAirline}-${stopDetails.flightNumber}`}</span>
                  </td>
                  <td className="text-center align-middle">
                    {stopDetails.origin} {`(${new Date(stopDetails.arrivalDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})`} 
                    <span style={{ color: '#ffc107', fontSize: '1.2em' }}> ➡ </span> 
                    {stopDetails.destination} {`(${new Date(stopDetails.departureDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})`}
                  </td>
                  <td className="text-center align-middle">{stopDetails.departureTerminal == '' ? '-' : stopDetails.departureTerminal}</td>
                  <td className="text-center align-middle">{stopDetails.arrivalTerminal == '' ? '-' : stopDetails.arrivalTerminal}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    };

    return (
      <Card className="flight-details-card checkout_details_card">
        <div className="airline-details">
          <Row className="airline-row-mobile">
            <Col md={16}>
              <div className="airline-details-left">
                <Row className="airline-row-mobile">
                  <Col md={6} xs={12}>
                    <div className="airline-img">
                      <img src={flightInfo.airLineLogo} alt="" />
                      <div className="token-number-flught">
                      <p style={{ fontWeight: 700 }}>
                        {flightInfo.airLineName}
                      </p>
                      &nbsp;
                      <p style={{ fontWeight: 700 }}>
                        ({flightInfo.flightSegments[0].marketingAirline}-
                        {flightInfo.flightSegments[0].flightNumber})
                      </p>
                    </div>
                    </div>
                  </Col>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Col md={8} className="src-dest-mobile">
                    <div className="airline-src-dest">
                      <p>{flightInfo.origin}</p>
                      <span className="airline-line"></span>
                      {flightInfo.flightSegments.map((flightSegInfo, index) =>
                        index !== 0 ? 
                        <div
                        className="stop_points"
                        key={flightSegInfo.segId}
                      >
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Change",
                            flightInfo
                          )}
                          className="citynames-tooltip"
                        >
                          <span className="break-city-name pointer_cursor">
                            {flightSegInfo.origin}
                          </span>
                          {flightInfo.flightSegments.length > 2 ? (
                            <span className="segment-span">
                              +{" "}
                              {flightInfo.flightSegments.length - 2}{" "}
                              Stop(s)
                            </span>
                          ) : null}
                        </Tooltip>
                      </div>
                      : null
                      )}
                      <span className="airline-line"></span>
                      <p>
                        {
                          flightInfo.flightSegments[
                            flightInfo.flightSegments.length - 1
                          ].destination
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={8} className="direct-text">
              <p>
                Journey Date:{" "}
                {dateFormat(
                  flightInfo.flightSegments[0].departureDateTime,
                  "dd mmm (ddd)"
                )}{" "}
              </p>
              <p> Stop:{" "}
                {flightInfo.flightSegments.length === 1 ? (
                  "Direct"
                ) : (
                  <>
                    {flightInfo.flightSegments.length - 1}
                    stop(s)
                  </>
                )}
              </p>
              {agent ? (
                <p>
                  Fare Type:{" "}
                  {faretype}
                </p>
              ) : null}
            </Col>
          </Row>
          {agent ? (    <Row className="airline-row-mobile">
          <Col md={24}>  {flightInfo.fareFamilies.fareFamilies[0].purchaseType =="Block" && <p className="hold"> {flightInfo.airLineName} Airline Can Either Be Booked OR Hold </p>} </Col>
          </Row>   ) : null}
          {agent ? (    <Row className="airline-row-mobile">
          <Col md={24}>  {flightInfo.fareFamilies.fareFamilies[0].purchaseType !="Block" && <p className="hold"> {flightInfo.airLineName} Airline Can Only Be Booked But Can't Be Hold </p>} </Col>
          </Row>   ) : null}
        </div>
        <div className="trip-details">
          <Row>
            <FlightTimings
              flightInfo={flightInfo}
              airSearchData={props.flightSearchObj}
              fareFamilies={flightInfo.fareFamilies}
              isSearch ={true}
            />
          </Row>
          
        </div>
      </Card>
    );
  };

  return (
    <div className="flight-details-container">
      <div className="change-flight-details">
        <h3>Flight Details</h3>
        <p
          onClick={() => {
            goTo();
          }}
        >
          Change Flights
        </p>
      </div>

      {props.selectedFlight.length > 0 ? (
        travelType === "oneWay" ? (
          <FlightCard flightInfo={props.selectedFlight[0]} />
        ) : (
          props.selectedFlight.map((flight) => (
            <FlightCard flightInfo={flight} />
          ))
        )
      ) : null}
    </div>
  );
};

export default DetailsCard;
