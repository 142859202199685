import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export const dateFormat = "DD-MM-YYYY";
export const oriDateFormat = "YYYY-MM-DD";

export const renderReffrence = (ref, rec) => {
  const type = rec.type;
  let url = "";
  switch (type) {
    case "Flight": {
      url = "flight/ticket";
      break;
    }
    case "Bus": {
      url = "bus/ticket";
      break;
    }
    case "Hotel": {
      url = "hotel/ticket";
      break;
    }
    case "Activities": {
      url = "activities/ticket";
      break;
    }
    case "BuildPackage": {
      url = "buildpackage/ticket";
      break;
    }
  }

  return <Link target="_blank" to={url + "?ref=" + ref}>{ref}</Link>;
};

export const getHotelStatus = (status) => {
  switch (status) {
    case 1: {
      return <p style={{ color: "#FFA500" }}>FAILED</p>;
    }
    case 2: {
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;
    }
    case 3: {
      return <p style={{ color: "#FF0000" }}>CANCELED</p>;
    }
    case 4: {
      return <p style={{ color: "#FF0000" }}>PENDING</p>;
    }
    case 5: {
      return <p style={{ color: "#f9e218" }}>REJECTED</p>;
    }
    case 6: {
      return <p style={{ color: "#FF0000" }}>HOLD</p>;
    }
    case 7: {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
    }
    case 8: {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
    }
    case 9: {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
    }
    case "FAILED": {
      return <p style={{ color: "#FFA500" }}>FAILED</p>;
    }
    case "CONFIRMED": {
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;
    }
    case "CANCELED": {
      return <p style={{ color: "#FF0000" }}>CANCELED</p>;
    }
    case "PENDING": {
      return <p style={{ color: "#FF0000" }}>PENDING</p>;
    }
    case "REJECTED": {
      return <p style={{ color: "#f9e218" }}>REJECTED</p>;
    }
    case "HOLD": {
      return <p style={{ color: "#FF0000" }}>HOLD</p>;
    }
    case "CANCELLATIONREQUESTED": {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
    }
    case "CANCELLATIONPENDING": {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
    }
    case "CANCELLATIONPROGRESS": {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
    }

    default:
      return;
  }
};

export const getStatus = (status) => {
  switch (status) {
    case "BLOCKED": {
      return <p style={{ color: "#FFA500" }}>{status}</p>;
    }
    case "CONFIRMED": {
      return <p style={{ color: "#008000" }}>{status}</p>;
    }
    case "BOOKED": {
      return <p style={{ color: "#008000" }}>{status}</p>;
    }
    case "CANCELLED": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "CREATED": {
      return <p style={{ color: "#f9e218" }}>{status}</p>;
    }
    case "HOLD": {
      return <p style={{ color: "#f9e218" }}>{status}</p>;
    }
    case "PARTIALLY CANCELLED": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "ALREADYCANCELLED": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "PENDING": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "FAILED": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "REJECTED": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "CancellationRequest": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "CancellationPending": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "CancellationInProgress": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    case "VOUCHERED": {
      return <p style={{ color: "#FF0000" }}>{status}</p>;
    }
    default:
      return;
  }
};

export const allColumns = [
  {
    title: "Bked By",
    dataIndex: "BookedBy",
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
  },
  {
    title: "opr",
    dataIndex: "operator",
    key: "operator",
  },
  {
    title: "RefNo",
    dataIndex: "referenceNumber",
    render: (text, record) => renderReffrence(text, record),
    sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
  },

  {
    title: "Serv Type",
    dataIndex: "type",
    key: "type",
  },

  {
    title: "Src",
    dataIndex: "from",
    render: (text, record) => {
      return record.type == "Flight" ? record?.oneWaySegment[0]?.origin : text;
    },
  },
  {
    title: "Dest",
    dataIndex: "to",
    render: (_, record) => {
      if (record.type == "Flight") {
        let length = record.oneWaySegment.length;
        return record?.oneWaySegment[length - 1]?.destination;
      } else {
        return "";
      }
    },
  },
  {
    title: "Htl Name",
    dataIndex: "HotelName",
    key: "HotelName",
    render: (text, rec) => (rec.type == "Hotel" ? rec.HotelName : text),
  },
  {
    title: "Jrny Date",
    dataIndex: "journeyDate",
    key: "journeyDate",
    render: (text, record) => {
      if (record.type == "Flight") {
        return text ? moment(text).format("DD-MM-YYYY") : null;
      } else {
        return text;
      }
    },
  },
  {
    title: "Arr Trml",
    dataIndex: "oneWaySegment",
    render: (text, record) =>
      record.type == "Flight" ? text[0]?.arrivalTerminal : "",
  },
  {
    title: "Arr Date",
    dataIndex: "oneWaySegment",
    render: (text, record) =>
      record.type == "Flight"
        ? moment(text[0]?.arrivalDateTime, oriDateFormat).format(dateFormat)
        : "",
  },

  {
    title: "Dept Trml",
    dataIndex: "oneWaySegment",
    render: (text, record) => {
      if (record.type == "Flight") {
        let length = text.length;
        return text[length - 1]?.departureTerminal;
      } else {
        return "";
      }
    },
  },
  {
    title: "Dept Date",
    dataIndex: "oneWaySegment",
    render: (text, record) => {
      if (record.type == "Flight") {
        let length = text.length;
        return moment(
          text[length - 1]?.departureDateTime,
          oriDateFormat
        ).format(dateFormat);
      } else {
        return "";
      }
    },
  },
  {
    title: "ChkIn Date",
    dataIndex: "CheckInDate",
    key: "CheckInDate",
    render: (text) =>
      text ? moment(text, oriDateFormat).format(dateFormat) : "",
  },
  {
    title: "ChkOut Date",
    dataIndex: "CheckOutDate",
    key: "CheckOutDate",
    render: (text) =>
      text ? moment(text, oriDateFormat).format(dateFormat) : "",
  },

  {
    title: "Adults",
    render: (record) =>
      record.type == "Flight" ? record.pax.adults : record.AdultCount,
  },
  {
    title: "Childs",
    render: (_, record) =>
      record.type == "Flight" ? record.pax.childCount : record.ChildCount,
  },
  {
    title: "Infant",
    dataIndex: "pax",
    render: (text, record) => (record.type == "Flight" ? text.infantCount : 0),
  },
  {
    title: "Optr Name",
    dataIndex: "operator",
    key: "operator",
  },

  {
    title: "Bkng Status",
    dataIndex: "BookingStatus",
    key: "status",

    render: (status, record) => {
      return record.type != "Hotel"
        ? getStatus(status)
        : getHotelStatus(status);
    },
  },
  {
    title: "Pax Name ",
    dataIndex: "guestName",
  },
  {
    title: "Pax Email",
    dataIndex: "guestEmaiId",
  },
  {
    title: "Pax Mobile ",
    dataIndex: "guestMobileNo",
  },
  {
    title: "Pymnt Mode",
    dataIndex: "isPartPayment",
    render: (isPartPayment, rec) =>
      isPartPayment && rec?.BookingStatus === "CONFIRMED"
        ? isPartPayment === true
          ? "Part Payment"
          : "Full Payment"
        : "",
  },
  {
    title: "Due Amt(Rs.)",
    dataIndex: "isPartPayment",
    render: (isPartPayment, rec) =>
      isPartPayment && rec?.BookingStatus === "CONFIRMED" ? (
        isPartPayment === true && rec?.dueAmount ? (
          <p style={{ color: "#FF0000", fontWeight: "600" }}>
            {Number(rec?.dueAmount).toFixed(2)}
          </p>
        ) : (
          ""
        )
      ) : (
        ""
      ),
  },
  {
    title: "Part Pymnt Amt(Rs.)",
    dataIndex: "isPartPayment",
    render: (isPartPayment, rec) =>
      isPartPayment && rec?.BookingStatus === "CONFIRMED"
        ? isPartPayment === true && rec?.partPaymentAmount
          ? rec?.partPaymentAmount
          : ""
        : "",
  },
  {
    title: "Amt(Rs.)",
    dataIndex: "totalPrice",
    render: (price, rec) =>
      rec?.type === "Activities" ? rec.serviceTotal : price,
  },
];

export const activitiesColumns = [
  {
    title: "Reference number",
    dataIndex: "referenceNumber",
    render: (text, record) => renderReffrence(text, record),
    sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
  },
  {
    title: "Tour Name",
    dataIndex: "tourName",
    sorter: (a, b) => a.tourName?.localeCompare(b.tourName),
  },
  {
    title: "Tour Option",
    dataIndex: "optionName",

    sorter: (a, b) => a.optionName?.localeCompare(b.optionName),
  },
  {
    title: "Booking Date",
    // dataIndex: "journeyDate",
    key: "bookingDate",
    render: (text) =>
      text.bookingDate != undefined
        ? moment(text.bookingDate).format("DD-MM-YYYY")
        : "",
    sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
  },
  {
    title: "Jrny Date",
    dataIndex: "journeyDate",
  },

  {
    title: "Pax Name ",
    dataIndex: "guestName",
    sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
  },
  {
    title: "PAx Email",
    dataIndex: "guestEmaiId",
    sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
  },
  {
    title: "Pax Mobile ",
    dataIndex: "guestMobileNo",
    sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
  },
  {
    title: "Adults",
    dataIndex: "pax",
    render: (text) => text.adults,
  },
  {
    title: "Childs",
    dataIndex: "pax",
    render: (text) => text.childCount,
  },
  {
    title: "Infant",
    dataIndex: "pax",
    render: (text) => text.infantCount,
  },
  {
    title: "Booking Status",
    dataIndex: "BookingStatus",
    key: "BookingStatus",
    render: (BookingStatus) => getStatus(BookingStatus),
    sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
  },
  {
    title: "Pymnt Mode",
    dataIndex: "isPartPayment",
    render: (isPartPayment, rec) =>
      isPartPayment && rec?.BookingStatus === "CONFIRMED"
        ? isPartPayment === true
          ? "Part Payment"
          : "Full Payment"
        : "",
  },
  {
    title: "Due Amt(Rs.)",
    dataIndex: "isPartPayment",
    render: (isPartPayment, rec) =>
      isPartPayment && rec?.BookingStatus === "CONFIRMED" ? (
        isPartPayment === true && rec?.dueAmount ? (
          <p style={{ color: "#FF0000", fontWeight: "600" }}>
            {Number(rec?.dueAmount).toFixed(2)}
          </p>
        ) : (
          ""
        )
      ) : (
        ""
      ),
  },
  {
    title: "Part Pymnt Amt(Rs.)",
    dataIndex: "isPartPayment",
    render: (isPartPayment, rec) =>
      isPartPayment && rec?.BookingStatus === "CONFIRMED"
        ? isPartPayment === true && rec?.partPaymentAmount
          ? rec?.partPaymentAmount
          : ""
        : "",
  },
  {
    title: "Amt(Rs.)",
    dataIndex: "serviceTotal",
  },
];

export const buildPackageColumns = [
  {
    title: "Reference number",
    dataIndex: "referenceNumber",
    render: (text, record) => renderReffrence(text, record),
    sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
  },
  {
    title: "Booking Date",
    // dataIndex: "journeyDate",
    key: "bookingDate",
    render: (text) =>
      text.bookingDate != undefined
        ? moment(text.bookingDate).format("DD-MM-YYYY")
        : "",
    sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
  },
  {
    title: "Jrny Date",
    dataIndex: "journeyDate",
  },

  {
    title: "Pax Name ",
    dataIndex: "guestName",
    sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
  },
  {
    title: "PAx Email",
    dataIndex: "guestEmaiId",
    sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
  },
  {
    title: "Pax Mobile ",
    dataIndex: "guestMobileNo",
    sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
  },
  {
    title: "Adults",
    dataIndex: "pax",
    render: (text) => text.adults,
  },
  {
    title: "Childs",
    dataIndex: "pax",
    render: (text) => text.childCount,
  },
  {
    title: "Infant",
    dataIndex: "pax",
    render: (text) => text.infantCount,
  },
  {
    title: "Booking Status",
    dataIndex: "BookingStatus",
    key: "BookingStatus",
    render: (BookingStatus) => getStatus(BookingStatus),
    sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
  },
  {
    title: "Payment Mode",
    dataIndex: "PartPayment",
    render: (PartPayment) =>
      PartPayment?.isPartPayment ? "Part Payment" : "Full Payment",
  },
  {
    title: "Due Amount",
    dataIndex: "PartPayment",
    render: (PartPayment) =>
      PartPayment?.dueAmount ? (
        <p style={{ color: "#FF0000", fontWeight: "600" }}>
          {Number(PartPayment?.dueAmount).toFixed(2)}
        </p>
      ) : (
        ""
      ),
  },
  {
    title: "Part Payment Amount",
    dataIndex: "PartPayment",
    render: (PartPayment) => PartPayment?.partPaymentAmount ?? "",
  },
  {
    title: "Total Amount",
    dataIndex: "serviceTotal",
  },
];
export const busColumns = [
  {
    title: "Booked By",
    dataIndex: "BookedBy",
  },
  {
    title: "RefNo",
    dataIndex: "referenceNumber",
    render: (text, record) => renderReffrence(text, record),
    sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
  },
  {
    title: "opr",
    dataIndex: "operator",
    key: "operator",
  },
  {
    title: "Bkng Date",
    // dataIndex: "journeyDate",
    key: "bookingDate",
    render: (text) =>
      text.bookingDate != undefined
        ? moment(text.bookingDate).format("DD-MM-YYYY")
        : "",
    sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
  },
  {
    title: "Jrny Date",
    dataIndex: "journeyDate",
  },
  {
    title: "Src",
    dataIndex: "from",
    key: "from",
  },
  {
    title: "Dest",
    dataIndex: "to",
    key: "from",
  },
  {
    title: "Pax Name ",
    dataIndex: "guestName",
    sorter: (a, b) => a.guestName - b.guestName,
  },
  {
    title: "Amt(Rs.)",
    dataIndex: "totalPrice",
  },
  {
    title: "Pax Email",
    dataIndex: "guestEmaiId",
    sorter: (a, b) => a.guestEmaiId - b.guestEmaiId,
  },
  {
    title: "Pax Mobile ",
    dataIndex: "guestMobileNo",
    sorter: (a, b) => a.guestMobileNo - b.guestMobileNo,
  },
  {
    title: "Bkng Status",
    dataIndex: "BookingStatus",
    render: (BookingStatus) => getStatus(BookingStatus),
    sorter: (a, b) => a.BookingStatus - b.BookingStatus,
  },
];

export const hotelColumns = [
  {
    title: "Bked By",
    dataIndex: "BookedBy",
  },
  {
    title: "RefNo",
    dataIndex: "referenceNumber",
    render: (text, record) => renderReffrence(text, record),
    sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
  },
  {
    title: "Htl Name",
    dataIndex: "HotelName",
    key: "HotelName",
  },
  {
    title: "ChkIn Date",
    dataIndex: "CheckInDate",
    key: "CheckInDate",
    render: (text) => moment(text, oriDateFormat).format(dateFormat),
  },
  {
    title: "ChkOut Date",
    dataIndex: "CheckOutDate",
    key: "CheckOutDate",
    render: (text) => moment(text, oriDateFormat).format(dateFormat),
  },
  {
    title: "Jrny Date",
    dataIndex: "journeyDate",
  },

  {
    title: "Bkng Status",
    dataIndex: "BookingStatus",
    key: "BookingStatus",
    render: (status) => getHotelStatus(status),
  },
  {
    title: "Rooms",
    dataIndex: "NoOfRooms",
  },
  {
    title: "Adults",
    dataIndex: "pax",
    render: (value) => value.adults,
  },
  {
    title: "Childs",
    dataIndex: "pax",
    render: (value) => value.childCount,
  },
  {
    title: "Amt(Rs.)",
    dataIndex: "totalPrice",
  },
  {
    title: "Pax Email",
    dataIndex: "guestEmaiId",
  },
  {
    title: "Pax Mobile ",
    dataIndex: "guestMobileNo",
  },
  {
    title: "Star Rtng",
    dataIndex: "StarRating",
  },
];
